import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import { useStaticQuery, graphql } from "gatsby"
import breakpoint from 'styled-components-breakpoint';

import Heading from 'components/global/Heading'
import Card from 'components/global/Card'

const StyledTitleContainer = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: 120px 0 60px;
  flex-direction: column;
  ${breakpoint('md')`
    flex-direction: row;
  `}
  h2 {
    margin: 0 15px 0 0;
  }
  h3 {
    font-size: 26px;
    font-weight: 400;
    color: ${props => props.theme.colors.greyDark}
  }
`
const StyledCardsContainer = styled.div`
  display: flex;
  justify-content: center;
  & > div {
    &:nth-child(2) {
      display: none;
      ${breakpoint('md')`
        display: flex;
      `}
    }
    &:nth-child(3) {
      display: none;
      ${breakpoint('xl')`
        display: flex;
      `}
    }
  }
`

const RealizationsSection = ({ activeExpertise }) => {

  const data = useStaticQuery(graphql`
    query {
      allContentfulArticle(filter: {type: {eq: "Projet"}}) {
        nodes {
          type
          id
          title
          slug
          tags
          heroImage {
            gatsbyImageData(
              width: 660
              height: 450
            )
          }
          accroche {
            accroche
          }
        }
      }
    }
  `)

  const projects = data.allContentfulArticle.nodes
  const [realizations, setRealizations] = useState([])

  useEffect(() => {
    const realizations = []
    projects.map(project => {
      const someProjects = project.tags.some(tag => tag === activeExpertise)
      if (someProjects) {
        realizations.push(project)
        return null
      } else {
        return null
      }
    })
    setRealizations(realizations.slice(0, 3))
  }, [activeExpertise, projects])

  const realizationsRender = realizations.map(realization => {
    return <Card key={realization.id} post={realization} isDate={false} />
  })

  return (
    <>
      <StyledTitleContainer>
        <Heading section>Réalisations</Heading>
        <h3>{activeExpertise}</h3>
      </StyledTitleContainer>
      <StyledCardsContainer>
        {realizationsRender}
      </StyledCardsContainer>
    </>
  )
}

export default RealizationsSection
