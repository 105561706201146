import React, { useState, useEffect } from "react"

import Seo from 'components/global/Seo'
import Layout from '../components/global/Layout'
import Container from 'components/global/Container'
import PageHero from 'components/global/PageHero'
import ExpertisesSection from 'components/pages/expertises/ExpertisesSection'

const Expertises = ({ location }) => {

  const [expertiseLinked, setExpertiseLinked] = useState('Smart territoires – City – Building')

  useEffect(() => {
    if (location.state != null) {
      if (location.state.expertiseLinked != null) {
        setExpertiseLinked(location.state.expertiseLinked)
      }
    }
  }, [location])

  return (
    <>
      <Seo pageTitle="Expertises" />
      <Layout>
        <Container>
          <PageHero
            pageTitle="Expertises"
            pageDecription="Les services que nous vous proposons vont du conseil, de l’analyse et de l’élaboration du dossier de consultation jusqu’au suivi spécifique de votre projet. Nous agissons au cours de toutes les étapes du projet : analyse préalable et identification des besoins, choix des outils/solutions, accompagnement et suivi à la mise en œuvre."
            imgName="expertises"
          />
          <ExpertisesSection expertiseLinked={expertiseLinked} />
        </Container>
      </Layout>
    </>
  )
}

export default Expertises

