import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import ExpertisesTabs from 'components/global/ExpertisesTabs'
import ExpertiseContent from './ExpertiseContent'
import RealizationsSection from '../RealizationsSection'

const StyledContainer = styled.div`
  margin-bottom: 60px;
`

const ExpertisesSection = ({ expertiseLinked }) => {

  const [activeExpertise, setActiveExpertise] = useState(expertiseLinked)

  useEffect(() => {
    setActiveExpertise(expertiseLinked)
  }, [expertiseLinked])

  const handleActiveExpertise = (activeExpertise) => {
    setActiveExpertise(activeExpertise)
  }

  return (
    <StyledContainer>
      <ExpertisesTabs activeExpertise={activeExpertise} activeExpertiseCallBack={handleActiveExpertise} />
      <ExpertiseContent activeExpertise={activeExpertise} />
      <RealizationsSection activeExpertise={activeExpertise} />
    </StyledContainer>
  )
}

export default ExpertisesSection
