import React from 'react'
import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint';

import Tag from 'components/global/Tag'
import expertisesData from 'data/expertises'

const StyledContainer = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column-reverse;
  ${breakpoint('lg')`
    margin-top: 70px;
    flex-direction: row;
    & > * {
      flex: 0 0 47%;
    }
  `}
  img {
    border-radius: 20px;
    max-height: 1000px;
  }
`
const StyledDescriptionContainer = styled.div`
  padding: 40px 0;
  ${breakpoint('lg')`
    padding: 40px 0 40px 70px;
  `}
  ${breakpoint('xl')`
    padding: 40px 70px;
  `}
  legend {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: ${props => props.theme.colors.greyDark};
    margin-bottom: 25px;
  }
  h2 {
    font-size: 30px;
    font-weight: 900;
    line-height: 41px;
  }
  p {
    margin: 25px 0;
    &:first-of-type {
      font-weight: 700;
      font-size: 18px;
    }
  }
  h3 {
    font-size: 16px;
    margin-bottom: 5px;
    font-weight: 700;
  }
  ul {
    margin-bottom: 25px;
    padding-left: 30px;
    li {
      list-style: initial;
    }
  }
  video {
    width: 100%;
  }
`
const StyledDomainsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`

const ExpertiseContent = ({ activeExpertise }) => {

  const expertise = expertisesData.find(expertise => expertise.name === activeExpertise)


  const domainRender = expertise.domains?.map(domain => {
    return <Tag key={domain}>{domain}</Tag>
  })

  return (
    <StyledContainer>
      {expertise.img}
      <StyledDescriptionContainer>
        <legend>Expertise</legend>
        <h2>{expertise.name}</h2>
        <p>{expertise.introParagraph}</p>
        {expertise.description}
        {expertise.domains ? (
          <h3>Domaines : </h3>
        ) : null}
        <StyledDomainsContainer>
          {domainRender}
        </StyledDomainsContainer>
      </StyledDescriptionContainer>
    </StyledContainer>
  )
}

export default ExpertiseContent
