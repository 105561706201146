import React from 'react';
import breakpoint from 'styled-components-breakpoint';
import styled from 'styled-components'

import expertises from 'data/expertises'

const StyledContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  border: 2px solid ${props => props.theme.colors.grey};
  border-radius: ${props => props.theme.baseBorderRadius};
  flex-wrap: wrap;
  margin-top: 90px;
`
const StyledExpertiseTab = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  position: relative;
  & > * {
    transition: all .2s ${props => props.theme.baseCubicBezier};
  }
  &:hover {
    div {
      background-color: ${props => props.theme.colors.brand};
      svg {
        fill: ${props => props.theme.colors.white};
      }
    }
    p {
      visibility: visible;
      opacity: 1;
    }
  }
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    border-radius: ${props => props.theme.baseBorderRadius};
    background-color: ${props => props.isActive ? props.theme.colors.brand : 'transparent'};
    svg {
      width: 100%;
      max-width: 50px;
      max-height: 50px;
      transition: all .2s ${props => props.theme.baseCubicBezier};
      fill: ${props => props.isActive ? props.theme.colors.white : props.theme.colors.brand};
    }
  }
  p {
    white-space: nowrap;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    color: ${props => props.theme.colors.brand};
    bottom: -30px;
    display: none;
    ${breakpoint('xl')`
      display: block;
    `}
  }
`

const ExpertisesTabs = ({ activeExpertise, activeExpertiseCallBack }) => {

  const expertisesRender = expertises.map(expertise => {
    return (
      <StyledExpertiseTab
        key={expertise.name}
        onClick={() => activeExpertiseCallBack(expertise.name)}
        isActive={expertise.name === activeExpertise}
      >
        <div>
          {expertise.icon}
        </div>
        <p>{expertise.name}</p>
      </StyledExpertiseTab>
    )
  })

  return (
    <StyledContainer>
      {expertisesRender}
    </StyledContainer>
  )
}

export default ExpertisesTabs
